export const utilityData = [
  {
    id: "B.ICE-DIAMOND_PLATINUM-UT3",
    img: "images/fitting/B.ICE-DIAMOND_PLATINUM-UT3.Webp",
    title: "B.ICE-DIAMOND_PLATINUM-UT#3",
    link: "B.ICE-DIAMOND_PLATINUM-UT3",
    price: "770,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_PLATINUM-UT3",
  },
  {
    id: "B.ICE-DIAMOND_PLATINUM-UT4",
    img: "images/fitting/B.ICE-DIAMOND_PLATINUM-UT4.Webp",
    title: "B.ICE-DIAMOND_PLATINUM-UT#4",
    link: "B.ICE-DIAMOND_PLATINUM-UT4",
    price: "770,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_PLATINUM-UT4",
  },
  {
    id: "B.ICE-DIAMOND_PLATINUM-UT5",
    img: "images/fitting/B.ICE-DIAMOND_PLATINUM-UT5.Webp",
    title: "B.ICE-DIAMOND_PLATINUM-UT#5",
    link: "B.ICE-DIAMOND_PLATINUM-UT5",
    price: "770,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_PLATINUM-UT5",
  },
  {
    id: "B.ICE-DIAMOND_BLACK-UT3",
    img: "images/fitting/B.ICE-DIAMOND_BLACK-UT3.Webp",
    title: "B.ICE-DIAMOND_BLACK-UT#3",
    link: "B.ICE-DIAMOND_BLACK-UT3",
    price: "600,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_BLACK-UT3",
  },
  {
    id: "B.ICE-DIAMOND_BLACK-UT4",
    img: "images/fitting/B.ICE-DIAMOND_BLACK-UT4.Webp",
    title: "B.ICE-DIAMOND_BLACK-UT#4",
    link: "B.ICE-DIAMOND_BLACK-UT4",
    price: "600,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_BLACK-UT4",
  },
  {
    id: "B.ICE-DIAMOND_BLACK-UT5",
    img: "images/fitting/B.ICE-DIAMOND_BLACK-UT5.Webp",
    title: "B.ICE-DIAMOND_BLACK-UT#5",
    link: "B.ICE-DIAMOND_BLACK-UT5",
    price: "600,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_BLACK-UT5",
  },
  {
    id: "B.ICE-DIAMOND_BLUE-UT3",
    img: "images/fitting/B.ICE-DIAMOND_BLUE-UT3.Webp",
    title: "B.ICE-DIAMOND_BLUE-UT#3",
    link: "B.ICE-DIAMOND_BLUE-UT3",
    price: "600,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_BLUE-UT3",
  },
  {
    id: "B.ICE-DIAMOND_BLUE-UT4",
    img: "images/fitting/B.ICE-DIAMOND_BLUE-UT4.Webp",
    title: "B.ICE-DIAMOND_BLUE-UT#4",
    link: "B.ICE-DIAMOND_BLUE-UT4",
    price: "600,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_BLUE-UT4",
  },
  {
    id: "B.ICE-DIAMOND_BLUE-UT5",
    img: "images/fitting/B.ICE-DIAMOND_BLUE-UT5.Webp",
    title: "B.ICE-DIAMOND_BLUE-UT#5",
    link: "B.ICE-DIAMOND_BLUE-UT5",
    price: "600,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_BLUE-UT5",
  },
  {
    id: "B.ICE-DIAMOND_PINK-UT3",
    img: "images/fitting/B.ICE-DIAMOND_PINK-UT3.Webp",
    title: "B.ICE-DIAMOND_PINK-UT#3",
    link: "B.ICE-DIAMOND_PINK-UT3",
    price: "600,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_PINK-UT3",
  },
  {
    id: "B.ICE-DIAMOND_PINK-UT4",
    img: "images/fitting/B.ICE-DIAMOND_PINK-UT4.Webp",
    title: "B.ICE-DIAMOND_PINK-UT#4",
    link: "B.ICE-DIAMOND_PINK-UT4",
    price: "600,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_PINK-UT4",
  },
  {
    id: "B.ICE-DIAMOND_PINK-UT5",
    img: "images/fitting/B.ICE-DIAMOND_PINK-UT5.Webp",
    title: "B.ICE-DIAMOND_PINK-UT#5",
    link: "B.ICE-DIAMOND_PINK-UT5",
    price: "600,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_PINK-UT5",
  },
  {
    id: "B.ICE-DIAMOND_GREEN-UT3",
    img: "images/fitting/B.ICE-DIAMOND_GREEN-UT3.Webp",
    title: "B.ICE-DIAMOND_GREEN-UT#3",
    link: "B.ICE-DIAMOND_GREEN-UT3",
    price: "600,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_GREEN-UT3",
  },
  {
    id: "B.ICE-DIAMOND_GREEN-UT4",
    img: "images/fitting/B.ICE-DIAMOND_GREEN-UT4.Webp",
    title: "B.ICE-DIAMOND_GREEN-UT#4",
    link: "B.ICE-DIAMOND_GREEN-UT4",
    price: "600,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_GREEN-UT4",
  },
  {
    id: "B.ICE-DIAMOND_GREEN-UT5",
    img: "images/fitting/B.ICE-DIAMOND_GREEN-UT5.Webp",
    title: "B.ICE-DIAMOND_GREEN-UT#5",
    link: "B.ICE-DIAMOND_GREEN-UT5",
    price: "600,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_GREEN-UT5",
  },
  {
    id: "blackIceUT19",
    img: "images/fitting/BLACKICE-UT(19).Webp",
    title: "BLACK ICE - UT(19°)",
    link: "blackIceUT19",
    price: "500,000",
    src: "images/gtdProduct/blackIceUT19",
  },
  {
    id: "blackIceUT21",
    img: "images/fitting/BLACKICE-UT(21).Webp",
    title: "BLACK ICE - UT(21°)",
    link: "blackIceUT21",
    price: "500,000",
    src: "images/gtdProduct/blackIceUT21",
  },
  {
    id: "blackIceUT24",
    img: "images/fitting/BLACKICE-UT(24).Webp",
    title: "BLACK ICE - UT(24°)",
    link: "blackIceUT24",
    price: "500,000",
    src: "images/gtdProduct/blackIceUT24",
  },
];
