export const woodData = [
  {
    id: "B.ICE-DIAMOND_PLATINUM-UT3_W",
    img: "images/fitting/B.ICE-DIAMOND_PLATINUM-FW3.Webp",
    title: "B.ICE-DIAMOND_PLATINUM-FW#3",
    link: "B.ICE-DIAMOND_PLATINUM-UT3_W",
    price: "770,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_PLATINUM-UT3_W",
  },
  {
    id: "B.ICE-DIAMOND_PLATINUM-UT5_W",
    img: "images/fitting/B.ICE-DIAMOND_PLATINUM-FW5.Webp",
    title: "B.ICE-DIAMOND_PLATINUM-FW#5",
    link: "B.ICE-DIAMOND_PLATINUM-UT5_W",
    price: "770,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_PLATINUM-UT5_W",
  },
  {
    id: "B.ICE-DIAMOND_BLACK-UT3_W",
    img: "images/fitting/B.ICE-DIAMOND_BLACK-FW3.Webp",
    title: "B.ICE-DIAMOND_BLACK-FW#3",
    link: "B.ICE-DIAMOND_BLACK-UT3_W",
    price: "600,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_BLACK-UT3_W",
  },
  {
    id: "B.ICE-DIAMOND_BLACK-UT5_W",
    img: "images/fitting/B.ICE-DIAMOND_BLACK-FW5.Webp",
    title: "B.ICE-DIAMOND_BLACK-FW#5",
    link: "B.ICE-DIAMOND_BLACK-UT5_W",
    price: "600,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_BLACK-UT5_W",
  },
  {
    id: "B.ICE-DIAMOND_BLUE-UT3_W",
    img: "images/fitting/B.ICE-DIAMOND_BLUE-FW3.Webp",
    title: "B.ICE-DIAMOND_BLUE-FW#3",
    link: "B.ICE-DIAMOND_BLUE-UT3_W",
    price: "600,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_BLUE-UT3_W",
  },
  {
    id: "B.ICE-DIAMOND_BLUE-UT5_W",
    img: "images/fitting/B.ICE-DIAMOND_BLUE-FW5.Webp",
    title: "B.ICE-DIAMOND_BLUE-FW#5",
    link: "B.ICE-DIAMOND_BLUE-UT5_W",
    price: "600,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_BLUE-UT5_W",
  },
  {
    id: "B.ICE-DIAMOND_PINK-UT3_W",
    img: "images/fitting/B.ICE-DIAMOND_PINK-FW3.Webp",
    title: "B.ICE-DIAMOND_PINK-FW#3",
    link: "B.ICE-DIAMOND_PINK-UT3_W",
    price: "600,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_PINK-UT3_W",
  },
  {
    id: "B.ICE-DIAMOND_PINK-UT5_W",
    img: "images/fitting/B.ICE-DIAMOND_PINK-FW5.Webp",
    title: "B.ICE-DIAMOND_PINK-FW#5",
    link: "B.ICE-DIAMOND_PINK-UT5_W",
    price: "600,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_PINK-UT5_W",
  },
  {
    id: "B.ICE-DIAMOND_GREEN-UT3_W",
    img: "images/fitting/B.ICE-DIAMOND_GREEN-FW3.Webp",
    title: "B.ICE-DIAMOND_GREEN-FW#3",
    link: "B.ICE-DIAMOND_GREEN-UT3_W",
    price: "600,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_GREEN-UT3_W",
  },
  {
    id: "B.ICE-DIAMOND_GREEN-UT5_W",
    img: "images/fitting/B.ICE-DIAMOND_GREEN-FW5.Webp",
    title: "B.ICE-DIAMOND_GREEN-FW#5",
    link: "B.ICE-DIAMOND_GREEN-UT5_W",
    price: "600,000",
    src: "images/gtdProduct/B.ICE-DIAMOND_GREEN-UT5_W",
  },
  {
    id: "blackIceFW3",
    img: "images/fitting/BLACKICE-FW(3).Webp",
    title: "BLACK ICE - FW(#3)",
    link: "blackIceFW3",
    price: "600,000",
    src: "images/gtdProduct/blackIceFW3",
  },
  {
    id: "blackIceFW5",
    img: "images/fitting/BLACKICE-FW(5).Webp",
    title: "BLACK ICE - FW(#5)",
    link: "blackIceFW5",
    price: "600,000",
    src: "images/gtdProduct/blackIceFW5",
  },
];
